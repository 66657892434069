import styled from 'styled-components'
import { Media, color } from '@raylink-overseas/common/theme'

export const TabList = styled.div`
  margin-bottom: 80px;
  display: flex;
  justify-content: center;
  gap: 48px;
  ${Media.laptop`
    gap: 38px;
  `}
  ${Media.ipad`
    gap: 28px;
  `}
  ${Media.phone`
    margin-bottom: 0.76rem;
    flex-wrap: wrap;
    gap: 0.28rem;
  `}
`

export const TabItem = styled.div<{ active: boolean }>`
  padding: 32px 0;
  width: 152px;
  height: 148px;
  flex-shrink: 0;
  border-radius: 8px;
  background: ${({ active }) =>
    active
      ? 'linear-gradient(290deg, #445FFF 53.77%, #55BCEE 97.08%)'
      : '#fff'};
  box-shadow: ${({ active }) =>
    active
      ? '0px 20px 30px 0px rgba(204, 204, 204, 0.3)'
      : '0px 10px 30px 0px rgba(204, 204, 204, 0.2)'};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  .name {
    color: ${({ active }) => (active ? '#fff' : color.textDark)};
    font-size: 18px;
    font-weight: 500;
  }
  &:active {
    background: linear-gradient(90deg, #197ef3 0%, #445fff 100%);
    .name {
      color: #fff;
    }
    svg {
      path {
        fill: #fff;
      }
    }
  }

  ${Media.phone<{ active: boolean }>`
    padding: 0.36rem 0;
    width: 2.1rem;
    height: 1.96rem;
    border-radius: 0.12rem;
    gap: 0.08rem;
    background: ${({ active }) =>
      active ? 'linear-gradient(90deg, #197ef3 0%, #445fff 100%)' : '#f5f7ff'};
    box-shadow: none;

    .name {
      color: ${({ active }) => (active ? '#fff' : color.textDark)};
      font-size: 0.28rem;
    }
  `}
`

export const TabIconWrap = styled.div<{ active: boolean; width: number }>`
  height: 60px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  svg {
    height: 60px;
    width: 60px;
    path,
    rect {
      fill: ${({ active }) => (active ? '#fff' : color.textDark)};
    }
  }
  ${Media.phone<{ active: boolean; width: number }>`
    height: 0.8rem;
    svg {
      width: ${({ width }) => `${width}rem`};
      height: ${({ width }) => `${width}rem`};
    }
  `}
`

export const TabContent = styled.div`
  width: 1200px;
  overflow-x: hidden;
  ${Media.laptop`
    width: 1108px;
    margin: auto;
  `}
  ${Media.ipad`
    width: 896px;
  `}
  ${Media.phone`
    width: 6.86rem;
    overflow-x: visible;
  `}
`

export const TabContentList = styled.div<{ index: number }>`
  display: flex;
  transform: translateX(${({ index }) => -index * 1200}px);
  transition: transform 0.3s ease-in-out 0s;
  ${Media.laptop<{ index: number }>`
    transform: translateX(${({ index }) => -index * 1108}px);
  `}
  ${Media.ipad<{ index: number }>`
    transform: translateX(${({ index }) => -index * 896}px);
  `}
  ${Media.phone<{ index: number; width: number }>`
    transform: none;
  `}
`

export const ContentItem = styled.div<{ show: boolean }>`
  padding-bottom: 112px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .item-main {
    width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .bottom {
    margin-top: 59px;
    color: ${color.textDark};
    font-size: 18px;
    font-weight: 400;
    text-align: center;
  }
  ${Media.homeLaptop`
    padding-bottom: 88px;
  `}
  ${Media.laptop`
    padding-bottom: 64px;
    .item-main {
      width: 1108px;
    }
  `}
  ${Media.ipad`
    padding-bottom: 56px;
    .item-main {
      width: 896px;
    }
  `}

  ${Media.phone<{ show: boolean }>`
    padding-bottom: 0.76rem;
    width: 100%;
    display: ${({ show }) => (show ? 'flex' : 'none')};
    .item-main {
      width: 100%;
      flex-direction: column;
      gap: 0.6rem;
      padding: 0;
    }
    .bottom {
      margin-top: 0.64rem;
      font-size: 0.28rem;
    }
  `}
`

export const ContentText = styled.div`
  width: 484px;
  flex-shrink: 0;
  .title {
    margin-bottom: 16px;
    color: ${color.textBlack};
    font-size: 36px;
    font-weight: 800;
    line-height: 110%;
  }
  .description {
    color: ${color.textDark};
    font-size: 18px;
    font-weight: 400;
    line-height: 150%;
  }
  .introduction {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    .intro-item {
      line-height: 28px;
      color: ${color.textDark};
      font-size: 18px;
      font-weight: 400;
      display: flex;
      align-items: center;
      gap: 16px;
      .dot {
        height: 28px;
        align-self: flex-start;
        display: flex;
        align-items: center;
        &::before {
          content: '';
          display: block;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: ${color.primary};
        }
      }
    }
  }
  ${Media.ipad`
    width: 456px;
    .title {
      font-size: 30px;
    }
    .description {
      font-size: 16px;
    }
    .introduction {
      .intro-item {
        font-size: 16px;
      }
    }
  `}

  ${Media.phone`
    width: 100%;
    .title {
      margin-bottom: 0.32rem;
      font-size: 0.48rem;
      text-align: center
    }
    .description {
      padding: 0 0.2rem;
      font-size: 0.32rem;
    }
    .introduction {
      margin-top: 0.2rem;
      padding: 0 0.2rem;
      gap: 0.16rem;
      .intro-item {
        line-height: 0.48rem;
        font-size: 0.32rem;
        gap: 0.16rem;
        .dot {
          height: 0.48rem;
          &::before {
            width: 0.1rem;
            height: 0.1rem;
          }
        }
      }
    }
  `}
`

export const BtnWrap = styled.div`
  margin-top: 32px;
  display: flex;
  &.mobile {
    display: none;
  }
  ${Media.phone`
    margin-top: 0.44rem;
    padding: 0 0.14rem;
    justify-content: center;
    &.mobile {
      display: flex;
      margin: 0.56rem 0;
    }
    &.web {
      display: none;
    }
  `}
`
