import { Media } from '@raylink-overseas/common/theme'
import styled from 'styled-components'

export const MacBtnsWrap = styled.div`
  .text-for {
    font-size: 18px;
    font-weight: 450;
    line-height: 1.25;
    margin-bottom: 20px;
    color: #1f2329;
  }
  .mac-btns {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  ${Media.phone`
    .text-for {
      font-size: 0.32rem;
      margin-bottom: 0.2rem;
      text-align: center;
    }
    .mac-btns {
      flex-direction: column;
      gap: 0.24rem;
      a {
        width: 3rem;
        font-size: 0.28rem;
        padding: 0 0.2rem;
      }
    }
  `}
`
