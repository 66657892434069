import React, { useState, useEffect } from 'react'
import { useMedia, useHref } from '@raylink-overseas/common/hooks'
import { jumpTo } from '@raylink-overseas/common/utils'
import {
  downloadCount,
  type DownloadInfo,
} from '@raylink-overseas/common/utils/download'
import { Platform } from '@raylink-overseas/common/constants/platform'
import { DownloadForBtn } from '../../download/components/atoms'
import { MacBtnsWrap } from './atoms'

type Props = {
  downloadInfos: {
    [key: number]: DownloadInfo
  }
}

export default function MacBtns(props: Props) {
  const { downloadInfos } = props

  const paramsHref = useHref()
  const [isGuidance, setIsGuidance] = useState(false)

  const isMedia = useMedia()

  useEffect(() => {
    if (typeof window === 'undefined') return
    setIsGuidance(window.location.href.includes('guidance'))
  }, [])

  return (
    <MacBtnsWrap>
      <div className="text-for">Free Download for:</div>
      <div className="mac-btns">
        <DownloadForBtn
          id="download"
          href={downloadInfos?.[Platform.mac]?.downloadLinkObject?.arm64}
          rel="nofollow"
          onClick={() => {
            downloadCount(Platform.mac, downloadInfos?.[Platform.mac]?.version)
            // 在下载引导下载不需要跳转下载引导页
            if (!isMedia && !isGuidance) {
              jumpTo(paramsHref(`${window.location.origin}/download/guidance`))
            }
          }}
        >
          Apple Silicon Mac
        </DownloadForBtn>
        <DownloadForBtn
          id="download"
          href={downloadInfos?.[Platform.mac]?.downloadLinkObject?.x86_64}
          rel="nofollow"
          onClick={() => {
            downloadCount(Platform.mac, downloadInfos?.[Platform.mac]?.version)
            // 在下载引导下载不需要跳转下载引导页
            if (!isMedia && !isGuidance) {
              jumpTo(paramsHref(`${window.location.origin}/download/guidance`))
            }
          }}
        >
          Intel-Based Mac
        </DownloadForBtn>
      </div>
    </MacBtnsWrap>
  )
}
