import React from 'react'
import {
  TabList,
  TabItem,
  TabIconWrap,
  TabContent,
  TabContentList,
  ContentItem,
  ContentText,
  BtnWrap,
} from './atoms'
import { TabListItem } from '../download-tab/type'
import { DownloadInfo, getDownloadLink } from '@raylink-overseas/common/utils'
import { Platform } from '@raylink-overseas/common/constants/platform'

interface Props {
  tabList: TabListItem[]
  activeTab: number
  downloadInfos: {
    [key: number]: DownloadInfo
  }
  setActiveTab: React.Dispatch<React.SetStateAction<number>>
  setDownloadInfos: React.Dispatch<
    React.SetStateAction<{
      [key: number]: DownloadInfo
    }>
  >
}

const DownloadMain = ({
  tabList,
  activeTab,
  downloadInfos,
  setActiveTab,
  setDownloadInfos,
}: Props) => {
  const changeActive = async (index: number) => {
    setActiveTab(index)
    if (!downloadInfos[tabList[index].platform]) {
      const res = await getDownloadLink(tabList[index].platform)
      if (res) {
        setDownloadInfos(downloadInfos => {
          const copyDownloadInfos = { ...downloadInfos }
          copyDownloadInfos[tabList[index].platform] = res
          return copyDownloadInfos
        })
      }
      if (index === 0) {
        setDownloadInfos(downloadInfos => {
          const copyDownloadInfos = { ...downloadInfos }
          copyDownloadInfos[Platform.windowsGreen] = res
          return copyDownloadInfos
        })
      }
    }
  }

  return (
    <>
      <TabList>
        {tabList.map((item, index) => (
          <TabItem
            key={item.platform}
            active={index === activeTab}
            onClick={() => changeActive(index)}
          >
            <TabIconWrap active={index === activeTab} width={item.icon.mWidth}>
              <item.icon.img />
            </TabIconWrap>
            <div className="name">{item.name}</div>
          </TabItem>
        ))}
      </TabList>
      <TabContent>
        <TabContentList index={activeTab}>
          {tabList.map(({ content, platform }, index) => (
            <ContentItem key={platform} show={index === activeTab}>
              <div className="item-main">
                <ContentText>
                  <div className="title">{content.title}</div>
                  <BtnWrap className="mobile">{content.downloadBtn}</BtnWrap>
                  <div className="description">{content.description}</div>
                  {content.introduction && content.introduction.length > 0 && (
                    <div className="introduction">
                      {content.introduction?.map((intro, index) => (
                        <div className="intro-item" key={index}>
                          <div className="dot" />
                          {intro}
                        </div>
                      ))}
                    </div>
                  )}
                  <BtnWrap className="web">{content.downloadBtn}</BtnWrap>
                </ContentText>
                {content.imgBox}
              </div>
              <div className="bottom">
                *By installing and using Avica, you accept our{' '}
                <a
                  href="https://www.avica.link/terms-and-conditions.html"
                  target="_blank"
                  rel="nofollow noreferrer"
                >
                  Terms & Conditions
                </a>{' '}
                and our{' '}
                <a
                  href="https://www.avica.link/privacy-policy.html"
                  target="_blank"
                  rel="nofollow noreferrer"
                >
                  Privacy Policy
                </a>
                .
              </div>
            </ContentItem>
          ))}
        </TabContentList>
      </TabContent>
    </>
  )
}

export default DownloadMain
