import React, { useState, useEffect } from 'react'
import { QRCodeSVG } from 'qrcode.react'
import {
  getDownloadLink,
  downloadCount,
  type DownloadInfo,
  defaultPlatform as getDefaultPlatform,
} from '@raylink-overseas/common/utils/download'
import { Platform } from '@raylink-overseas/common/constants/platform'
import DownloadMain from '../download-main'
import { TabListItem } from './type'
import iconWindows from '@svg/download-windows.svg'
import iconMacOS from '@svg/download-macOS.svg'
import iconAndroid from '@svg/download-android.svg'
import iconIOS from '@svg/download-iOS.svg'
import iconWeb from '@svg/download-web.svg'
import AppStore from '@svg/btn-app-store.svg'
import DownloadIcon from '@svg/icon-download.svg'
import WinImg from '../../../../images/download/Windows_image.png'
import MacImg from '../../../../images/download/macOS_image.png'
import AndroidImg from '../../../../images/download/android_image.png'
import IosImg from '../../../../images/download/iOS_image.png'
import WebImg from '../../../../images/download/web_image.png'
import IosIcon from '../../../../images/download/iOS.svg'
import {
  AndroidBtnGroup,
  AndroidDownloadBtn,
  DownloadForBtn,
  DownloadInStore,
} from '../atoms'
import {
  DownloadTabWrap,
  WinMacWebImgBox,
  DownloadBtnWrap,
  AndroidIOSImgBox,
  BtnList,
  GreenWinWrapWeb,
  GreenWinWrapMobile,
  MacIdentify,
  MacFloatTip,
} from './atoms'
import { jumpTo } from '@raylink-overseas/common/utils'
import { useMedia, useHref } from '@raylink-overseas/common/hooks'
import { PersonalLink } from '@raylink-overseas/common/constants/public'
import MacBtns from '../../../components/MacBtns'

type ItemType = TabListItem | undefined

const DownloadTab = () => {
  const paramsHref = useHref()
  const [downloadInfos, setDownloadInfos] = useState<{
    [key: number]: DownloadInfo
  }>({})
  const [showMacTip, setShowMacTip] = useState(false)

  const [activeTab, setActiveTab] = useState<number>(0)

  const isMedia = useMedia()

  const getWindowsGreens = (activeItem: ItemType, data: DownloadInfo) => {
    if (
      activeTab === Platform.windows ||
      activeItem?.platform === Platform.windows
    ) {
      getDownloadLink(Platform.windowsGreen).then(res => {
        if (res) {
          const copyDownloadInfos = { ...downloadInfos }
          copyDownloadInfos[Platform.windows] = data
          copyDownloadInfos[Platform.windowsGreen] = res
          setDownloadInfos(copyDownloadInfos)
        }
      })
    }
  }

  useEffect(() => {
    // 设置默认选中端
    const defaultPlatform = getDefaultPlatform().platform
    const activeItem = tabList.find((item, index) => {
      const result = item.platform === defaultPlatform
      if (result) {
        setActiveTab(index)
      }
      return result
    })

    if (activeItem) {
      getDownloadLink(activeItem.platform).then(res => {
        if (res) {
          if (activeItem?.platform === Platform.windows) {
            getWindowsGreens(activeItem, res)
          } else {
            const copyDownloadInfos = { ...downloadInfos }
            copyDownloadInfos[activeItem.platform] = res
            setDownloadInfos(copyDownloadInfos)
          }
        }
      })
    } else {
      getDownloadLink(activeTab).then(res => {
        if (res) {
          if (activeTab === Platform.windows) {
            getWindowsGreens(activeItem, res)
          } else {
            const copyDownloadInfos = { ...downloadInfos }
            copyDownloadInfos[activeTab] = res
            setDownloadInfos(copyDownloadInfos)
          }
        }
      })
    }
  }, [])

  /**
   * platform: 平台
   * icon: tab图标
   *    img: 图片
   *    mWidth: 移动端图标大小
   * name: tab名称
   * content: tab下面的内容
   *    title: 内容标题
   *    description: 下载描述
   *    introduction: 下载介绍
   *    downloadBtn: 下载按钮
   *    imgBox: 右侧图片
   */
  const tabList: TabListItem[] = [
    {
      platform: Platform.windows,
      icon: {
        img: iconWindows,
        mWidth: 0.8,
      },
      name: 'Windows',
      content: {
        title: 'Avica Personal for Windows',
        description:
          'Download and establish a remote desktop connection with Windows, Mac, Android, and iOS devices.',
        introduction: [
          <div>
            Current version: {downloadInfos[Platform.windows]?.version}
          </div>,
          'Available for Windows 7 or newer (64-bit)',
        ],
        downloadBtn: (
          <BtnList className="gap">
            <DownloadForBtn
              id="download"
              href={downloadInfos[Platform.windows]?.downloadLink}
              rel="nofollow"
              onClick={() => {
                downloadCount(
                  Platform.windows,
                  downloadInfos[Platform.windows]?.version
                )
                if (!isMedia) {
                  jumpTo(
                    paramsHref(`${window.location.origin}/download/guidance`)
                  )
                }
              }}
            >
              Free Download
            </DownloadForBtn>
            <GreenWinWrapWeb>
              <a
                className="green-download"
                id="download"
                href={downloadInfos[Platform.windowsGreen]?.downloadLink}
                rel="nofollow"
                onClick={() => {
                  downloadCount(
                    Platform.windowsGreen,
                    downloadInfos[Platform.windowsGreen]?.version
                  )
                }}
              >
                <DownloadIcon />
                Download Avica Portable
              </a>
              <div className="green-desc">
                Install free, to be accessed only
              </div>
            </GreenWinWrapWeb>
            {/* <DownloadForBtn
              id="download"
              isWhite
              height={48}
              href="https://download.avica.link/Avica_v6.2.7.9.msi"
              rel="nofollow"
            >
              Download MSI File
            </DownloadForBtn> */}
          </BtnList>
        ),
        imgBox: (
          <>
            <GreenWinWrapMobile>
              <a
                className="green-download"
                id="download"
                href={downloadInfos[Platform.windowsGreen]?.downloadLink}
                rel="nofollow"
                onClick={() => {
                  downloadCount(
                    Platform.windowsGreen,
                    downloadInfos[Platform.windowsGreen]?.version
                  )
                }}
              >
                <DownloadIcon />
                Download Avica Portable
              </a>
              <div className="green-desc">
                Install free, to be accessed only
              </div>
            </GreenWinWrapMobile>
            <WinMacWebImgBox>
              <img src={WinImg} alt="" />
            </WinMacWebImgBox>
          </>
        ),
      },
    },
    {
      platform: Platform.mac,
      icon: {
        img: iconMacOS,
        mWidth: 0.8,
      },
      name: 'macOS',
      content: {
        title: 'Avica Personal for Mac',
        description:
          'Download remote desktop client for Mac to simplify access to Windows, Mac, Android, and iOS devices.',
        introduction: [
          <div>Current version: {downloadInfos[Platform.mac]?.version}</div>,
          'Available for macOS 11.0 or newer',
        ],
        downloadBtn: (
          <div>
            <MacBtns downloadInfos={downloadInfos} />
            <MacIdentify
              onMouseEnter={() => setShowMacTip(true)}
              onMouseLeave={() => setShowMacTip(false)}
              haveBorder={!showMacTip}
            >
              How to identify a Mac&lsquo;s chip type?
            </MacIdentify>
          </div>
        ),
        imgBox: (
          <WinMacWebImgBox>
            <img src={MacImg} alt="" />
          </WinMacWebImgBox>
        ),
      },
    },
    {
      platform: Platform.android,
      icon: {
        img: iconAndroid,
        mWidth: 0.8,
      },
      name: 'Android',
      content: {
        title: 'Avica Personal for Android',
        description:
          'Access the Windows, Mac, Android, and iOS devices through your Android anytime, anywhere!',
        introduction: [
          <div>
            Current version: {downloadInfos[Platform.android]?.version}
          </div>,
          'Available for Android 8.0 or newer',
        ],
        downloadBtn: (
          <AndroidBtnGroup>
            <AndroidDownloadBtn
              id="download"
              className="btn-google-play"
              href={PersonalLink.PersonalGooglePlayLink}
              rel="nofollow"
              target="__blank"
            >
              <div className="qrcode">
                <QRCodeSVG
                  value={PersonalLink.PersonalGooglePlayLink}
                  size={130}
                />
              </div>
            </AndroidDownloadBtn>
            <AndroidDownloadBtn
              id="download"
              className="btn-galaxy-store"
              href={PersonalLink.PersonalGalaxyStoreLink}
              rel="nofollow"
              target="__blank"
            >
              <div className="qrcode">
                <QRCodeSVG
                  value={PersonalLink.PersonalGalaxyStoreLink}
                  size={130}
                />
              </div>
            </AndroidDownloadBtn>
            <AndroidDownloadBtn
              id="download"
              className="btn-app-gallery"
              href={PersonalLink.PersonalAppGalleryLink}
              rel="nofollow"
              target="__blank"
            >
              <div className="qrcode">
                <QRCodeSVG
                  value={PersonalLink.PersonalAppGalleryLink}
                  size={130}
                />
              </div>
            </AndroidDownloadBtn>
            <AndroidDownloadBtn
              id="download"
              className="btn-apk-download"
              href={downloadInfos[Platform.android]?.downloadLink}
              rel="nofollow"
              onClick={() => {
                downloadCount(
                  Platform.android,
                  downloadInfos[Platform.android]?.version
                )
              }}
            >
              <span>Download APK File</span>
              <div className="qrcode">
                <QRCodeSVG
                  value={downloadInfos[Platform.android]?.downloadLink}
                  size={130}
                />
              </div>
            </AndroidDownloadBtn>
          </AndroidBtnGroup>
        ),
        imgBox: (
          <AndroidIOSImgBox>
            <img src={AndroidImg} alt="" />
          </AndroidIOSImgBox>
        ),
      },
    },
    {
      platform: Platform.ios,
      icon: {
        img: iconIOS,
        mWidth: 0.8,
      },
      name: 'iOS',
      content: {
        title: 'Avica Personal for iOS',
        description:
          'Unleash unlimited remote desktop connections to Windows, Mac, Android and iOS devices from your iPhone/iPad.',
        introduction: [
          <div>Current version: {downloadInfos[Platform.ios]?.version}</div>,
          'Available for iOS 11.0 or newer',
        ],
        downloadBtn: (
          <DownloadBtnWrap>
            <div className="qrcode-wrap">
              <QRCodeSVG
                value={downloadInfos[Platform.ios]?.downloadLink}
                size={110}
              />
            </div>
            <div>
              <a
                id="download"
                href={downloadInfos[Platform.ios]?.downloadLink}
                target="_blank"
                rel="nofollow noreferrer"
              >
                <DownloadInStore>
                  <AppStore />
                </DownloadInStore>
              </a>
            </div>
          </DownloadBtnWrap>
        ),
        imgBox: (
          <AndroidIOSImgBox>
            <img src={IosImg} alt="" />
          </AndroidIOSImgBox>
        ),
      },
    },
    {
      platform: Platform.web,
      icon: {
        img: iconWeb,
        mWidth: 0.8,
      },
      name: 'Web',
      content: {
        title: 'Avica Web App',
        description:
          'Just click and connect! Access to Windows PC via web-based remote desktop.',
        introduction: [
          'Available for Microsoft Edge, Google Chrome, Apple Safari and Mozilla Firefox',
        ],
        downloadBtn: (
          <DownloadForBtn
            id="web-app"
            href="https://console.avica.link/remote"
            target="_blank"
            rel="noreferrer nofollow"
          >
            Launch in Browser
          </DownloadForBtn>
        ),
        imgBox: (
          <WinMacWebImgBox>
            <img src={WebImg} alt="" />
          </WinMacWebImgBox>
        ),
      },
    },
  ]

  return (
    <DownloadTabWrap>
      <DownloadMain
        tabList={tabList}
        activeTab={activeTab}
        downloadInfos={downloadInfos}
        setActiveTab={setActiveTab}
        setDownloadInfos={setDownloadInfos}
      />
      {/* mac悬浮提示，因为父级设置overflow: hidden;仍然会隐藏子级的position: absolute;，所以写在这里 */}
      {showMacTip && (
        <MacFloatTip
          className="mac-float-tip"
          onMouseEnter={() => setShowMacTip(true)}
          onMouseLeave={() => setShowMacTip(false)}
        >
          <div className="tip-desc">
            Download the proper Avica version according to your Mac&lsquo;s chip
            to enhance your remote experience.
          </div>
          <div className="tip-question">
            How to identify your Mac&lsquo;s chip type?
          </div>
          <div className="tip-desc choose">
            1. Choose Apple menu <img src={IosIcon} alt="" />
            &gt; About This Mac.
          </div>
          <div className="tip-desc">
            2. Check the Chip or Processor label in the Overview tab to
            determine if the chip is based on Intel or Apple.
          </div>
        </MacFloatTip>
      )}
    </DownloadTabWrap>
  )
}

export default DownloadTab
