import React from 'react'
import { Link } from 'gatsby'
import { Layout } from '@raylink-overseas/common/components'
import DownloadTab from './components/download-tab'
import DownloadWhy from './components/download-why'
import DownloadContact from './components/download-contact'
import { DownloadPage, DownloadWrapper, Title } from './atoms'

const Download = () => {
  return (
    <Layout pageType="download">
      <DownloadPage>
        <DownloadWrapper>
          <Title>
            <h1>Avica Personal Downloads</h1>
            <div className="sub-title">
              Download Avica remote desktop software for individual use on
              different platforms.
            </div>
            <Link className="switch-download-page" to="/downloads/business/">
              <span>Switch to Avica Business Downloads</span>
            </Link>
          </Title>
          <DownloadTab />
        </DownloadWrapper>
        <DownloadWhy />
        <DownloadContact />
      </DownloadPage>
    </Layout>
  )
}

export default Download
