import styled from 'styled-components'
import { Media, color } from '@raylink-overseas/common/theme'

export const DownloadTabWrap = styled.div`
  position: relative;
  .mac-float-tip {
    position: absolute;
    bottom: 40px;
    left: 0;
  }
  ${Media.homeLaptop`
    .mac-float-tip {
      bottom: 16px;
    }
  `}
  ${Media.laptop`
    .mac-float-tip {
      bottom: -6px;
    }
  `}
  ${Media.ipad`
    .mac-float-tip {
      bottom: -38px;
    }
  `}
  ${Media.phone`
    width: 100%;
    .mac-float-tip {
      left: 50%;
      bottom: 6.24rem;
      transform: translateX(-50%);
    }
  `}
`

export const WinMacWebImgBox = styled.div`
  img {
    width: 620px;
    height: 384px;
    vertical-align: bottom;
  }
  ${Media.ipad`
    img {
      width: 416px;
      height: auto;
    }
  `}
  ${Media.phone`
    img {
      width: 6.84rem;
      height: unset;
    }
  `}
`

export const AndroidIOSImgBox = styled.div`
  img {
    width: 475px;
    height: 413px;
  }
  ${Media.ipad`
    img {
      width: 416px;
      height: auto;
    }
  `}
  ${Media.phone`
    img {
      width: 6.48rem;
      height: 5.64rem;
    }
  `}
`

export const DownloadBtnWrap = styled.div`
  display: flex;
  gap: 16px;
  .qrcode-wrap {
    padding: 5px;
    border-radius: 8px;
    border: 1px solid #e6e6e6;
    background: #fff;
    svg {
      vertical-align: bottom;
    }
  }
  ${Media.phone`
    gap: 0.32rem;
    .qrcode-wrap {
      padding: 0.1rem;
      border-radius: 0.16rem;
    }
  `}
`
export const BtnList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &.gap {
    gap: 16px;
  }
  .apk-btn {
    padding: 0;
    border: 1px solid ${color.primary};
  }

  ${Media.phone`
    &.gap {
      gap: 0.32rem;
    }
  `}
`

export const GreenWinWrapWeb = styled.div`
  line-height: 1.5;
  margin-top: 16px;
  .green-download {
    display: flex;
    align-items: center;
    width: fit-content;
    font-size: 16px;
    color: ${color.primary};
    box-sizing: border-box;
    border-bottom: 1px solid transparent;
    svg {
      margin-right: 8px;
    }
    svg path {
      fill: ${color.primary};
    }
    &:hover {
      border-bottom: 1px solid ${color.primary};
    }
    &:active {
      border-bottom: 1px solid ${color.primary};
      color: #3450e9;
      svg path {
        fill: #3450e9;
      }
    }
  }
  .green-desc {
    padding: 1px 8px;
    margin-top: 10px;
    background: #f5f7ff;
    font-size: 16px;
    color: #848484;
    border-radius: 4px;
  }
  ${Media.phone`
    display: none;
  `}
`

export const GreenWinWrapMobile = styled.div`
  display: none;
  ${Media.phone`
    display: block;
    line-height: 1.5;
    margin-top: 0.04rem;
    .green-download {
      margin: auto;
      display: flex;
      align-items: center;
      width: fit-content;
      font-size: 0.32rem;
      color: ${color.primary};
      box-sizing: border-box;
      border-bottom: 1px solid ${color.primary};
      svg {
        margin-right: 0.16rem;
      }
      svg path {
        fill: ${color.primary};
      }
      &:active {
        border-bottom: 1px solid ${color.primary};
        color: #3450e9;
        svg path {
          fill: #3450e9;
        }
      }
    }
    .green-desc {
      padding: 1px 0.16rem;
      margin-top: 0.2rem;
      background: #f5f7ff;
      font-size: 0.32rem;
      color: #848484;
      border-radius: 0.08rem;
    }
  `}
`

export const MacIdentify = styled.div<{ haveBorder: boolean }>`
  position: relative;
  font-size: 16px;
  line-height: 1.5;
  color: #848484;
  border-bottom: ${({ haveBorder }) =>
    haveBorder ? '1px solid #848484' : '1px solid transparent'};
  margin-top: 20px;
  width: fit-content;
  ${Media.phone`
    font-size: 0.32rem;
    margin-top: 0.32rem;
  `}
`

export const MacFloatTip = styled.div`
  position: absolute;
  width: 356px;
  padding: 16px 20px;
  box-shadow: 0px 0px 20px 0px #cccccc;
  border-radius: 8px;
  z-index: 9999;
  background: #fff;
  font-size: 14px;
  line-height: 1.25;
  .tip-desc {
    color: #595959;
  }
  .tip-question {
    font-weight: 500;
    color: #1f2329;
    margin: 12px 0 4px;
  }
  .choose {
    margin-bottom: 4px;
  }
  ::after {
    content: '';
    position: absolute;
    background: #fff;
    width: 16px;
    height: 16px;
    top: -14px;
    left: 24px;
    transform: translate(-50%, 50%) rotate(45deg);
  }
  ${Media.phone`
    width: 6.96rem;
    padding: 0.32rem;
    box-shadow: 0px 0px 0.4rem 0px #cccccc;
    border-radius: 0.16rem;
    font-size: 0.28rem;
    .tip-question {
      margin: 0.24rem 0 0.08rem;
    }
    .choose {
      margin-bottom: 0.08rem;
    }
    ::after {
      width: 0.32rem;
      height: 0.32rem;
      top: -0.28rem;
      left: 50%;
      transform: translate(-50%, 50%) rotate(45deg);
    }
  `}
`
